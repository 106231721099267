exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-attorneys-js": () => import("./../../../src/pages/attorneys.js" /* webpackChunkName: "component---src-pages-attorneys-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-giving-back-js": () => import("./../../../src/pages/giving-back.js" /* webpackChunkName: "component---src-pages-giving-back-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-practice-areas-js": () => import("./../../../src/pages/practice-areas.js" /* webpackChunkName: "component---src-pages-practice-areas-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-attorney-js": () => import("./../../../src/templates/attorney.js" /* webpackChunkName: "component---src-templates-attorney-js" */),
  "component---src-templates-pa-js": () => import("./../../../src/templates/pa.js" /* webpackChunkName: "component---src-templates-pa-js" */)
}

